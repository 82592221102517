import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { DOCUMENT } from '@angular/common';
import { AdvanceTableService } from 'src/app/advance-table/advance-table.service';

import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { CompanyService } from 'src/app/organization/company.service';
const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public config: any = {};
  isNavbarCollapsed = true;
  isOpenSidebar: boolean;
  p: any;
  userDetails :any;
  id: any;
  profilePic: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private ad:AdvanceTableService,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private companyService:CompanyService,

  ) {
    this.userDetails = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : {}
    this.ad.userDetailsChanges.subscribe(changes =>{
      if(changes && changes._id){
        this.userDetails = changes
      }
    })
  }
  
  ngOnInit() {
    this.config = this.configService.configData;
    this.getCompany();
    this.getProfile();

  }
  getProfile() {
    this.authService.getMe().subscribe(
      (response: any) => {
        this.id= response.id,
        this.profilePic = response.profilePic;

      },
      (error) => {
        console.error('Error retrieving get me data:', error);
      }
    );
  }
  notifications: any[] = [
    {
      userImg: 'assets/images/user/user1.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail',
    },
    {
      userImg: 'assets/images/user/user2.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!',
    },
    {
      userImg: 'assets/images/user/user3.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.',
    },
    {
      userImg: 'assets/images/user/user4.jpg',
      userName: 'Ashton Cox',
      time: '5 hours ago',
      message: 'Lets break for lunch...',
    },
    {
      userImg: 'assets/images/user/user5.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail',
    },
    {
      userImg: 'assets/images/user/user6.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!',
    },
    {
      userImg: 'assets/images/user/user7.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.',
    },
  ];

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  companyDataa:any=[]

getCompany(){
  this.companyService.getCompany().subscribe(
    (companyData: any) => {
      this.companyDataa = companyData;
    },
    error => {
      console.error('Error retrieving location data:', error);
    }
  );
} 
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  // callSidemenuCollapse() {
  //   const hasClass = this.document.body.classList.contains('side-closed');
  //   if (hasClass) {
  //     this.renderer.removeClass(this.document.body, 'side-closed');
  //     this.renderer.removeClass(this.document.body, 'submenu-closed');
  //   } else {
  //     this.renderer.addClass(this.document.body, 'side-closed');
  //     this.renderer.addClass(this.document.body, 'submenu-closed');
  //   }
  // }
  get(){
    this.ad.getAllUserss().subscribe((res:any)=>{
      let statusData:Array<any> = res.data;
      console.log('table soiurce' , res)
      this.p= statusData.filter((pilot : any) => pilot._id== localStorage.getItem('currentUsers'));
      console.log("let filter", this.p )
    });
  }
  logout() {
   localStorage.removeItem('accesstoken')
  this.router.navigate(['/authentication/signin']);

}
onboarding()
{
  this.router.navigate(['/onboarding/onboard']);
}
profile() {
  this.router.navigate(['/organization/organizationdetails'], { fragment: 'profile' });
}
}

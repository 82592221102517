import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(public http: HttpClient) {
    
   }
   updateCompany(userCredentials:any,id:any){
    const token = localStorage.getItem('accesstoken');
    const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        });
    const routeLink = `${environment.API_DEV_URL}/company`;
    return this.http.put(routeLink , userCredentials,{headers})
  }
  getCompany(){
    const token = localStorage.getItem('accesstoken');
    const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        });
    const routeLink = `${environment.API_DEV_URL}/company`;
    return this.http.get(routeLink ,{headers})
  }
  updateLogo(userCredentials:any){
    console.log('userCredentials',userCredentials)

    const token = localStorage.getItem('accesstoken');
    const headers = new HttpHeaders({
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        });
    const routeLink = `${environment.API_DEV_URL}/company/logo`;
    return this.http.post(routeLink , userCredentials,{headers})
  }
}
